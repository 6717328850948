import { format, parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { parse as parseValues } from "vue-currency-input";

export default {
  data() {
    return {};
  },
  filters: {
    formatUserRole(role) {
      if (role == "admin") role = "Administrador";
      if (role == "analyst") role = "Analista";
      if (role == "user") role = "Consultor";
      if (role == "manager") role = "Gestor";
      if (role == "miles-manager") role = "Gestor de Milhas";
      if (role == "financial") role = "Financeiro";
      return role;
    },
    formatMobilePhone(value) {
      // Remove all non-numeric characters
      const cleanedNumber = value.replace(/\D/g, "");

      // Extract the parts of the cleaned number using substring
      const areaCode = cleanedNumber.substring(0, 2);
      const firstPart = cleanedNumber.substring(2, 7);
      const secondPart = cleanedNumber.substring(7, 11);

      // Combine the parts into the desired format
      return `(${areaCode}) ${firstPart} - ${secondPart}`;
    },
    formatLiveloTransactionDate(date) {
      let theDate = "";
      let splitDate = date.split("T");

      theDate = parse(
        `${splitDate[0]} ${splitDate[1]}`,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      theDate = format(theDate, "dd/MM/yyyy HH:mm");
      return theDate;
    },
    formatDateTime(date) {
      let theDate = "";
      if (date.includes("T")) {
        theDate = parse(date, "yyyy-MM-ddTHH:mm:ss", new Date());
        theDate = format(theDate, "dd/MM/yyyy HH:mm");
      } else {
        theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
        theDate = format(theDate, "dd/MM/yyyy HH:mm");
      }
      return theDate;
    },
    formatListPriceNumAndStr(price) {
      let thePrice = "";

      if (typeof price === "number") {
        thePrice = `${Number(parseFloat(price).toFixed(2)).toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL",
          }
        )}`;
      } else {
        thePrice = `${
          price ??
          Number(parseFloat(0).toFixed(2)).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }`;
      }

      return thePrice;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    removeLettersAndSpecialChars(str) {
      return str.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    formatDateTimeZeros(i) {
      if (i < 10) {
        return "0" + i;
      } else {
        return i;
      }
    },
    formatDateTimeObj(date) {
      return format(date, "dd/MM/yyyy HH:mm");
    },
    parseFloatToStr(value) {
      let theValue = 0,
        valuePieces,
        strValue = `${value}`;

      if (value && strValue.includes(".")) {
        valuePieces = strValue.split(".");
        theValue = `${valuePieces[0]},${
          valuePieces[1] ? valuePieces[1].slice(0, 2) : "00"
        }`;
      } else {
        theValue = `${value}`;
      }

      return theValue;
    },
    parseIntToStrWithZero(value) {
      let theValue = 0,
        strValue = `${value}`;

      if (!strValue.includes(",") || !strValue.includes(".")) {
        theValue;
      } else {
        theValue = `${value},00`;
      }

      return value;
    },

    formatDateModel(date, model, separator) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, model, {
            locale: ptBR,
          });
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }

        if (date.length == 10) {
          return date;
        }

        separator;

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), model, {
            locale: ptBR,
          });
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }
      }
    },
    formatPriceBR(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString(
        "pt-BR",
        {}
      )}`;
    },
    formatPricePrBr(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`;
    },
    formatPricePtBrComma(price) {
      return price
        ? `${Number(parseValues(price).toFixed(2)).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}`
        : Number(0).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
    },
    formatPercentage(percentage) {
      let thePercentage = 0;
      if (percentage) {
        thePercentage = percentage;
      }
      return `${Number(parseFloat(thePercentage).toFixed(2)).toLocaleString(
        "pt-BR"
      )}`;
    },
    formatMultiPricePtBr(price) {
      let thePrice = 0;
      if (price) {
        thePrice = price;
      }

      if (thePrice.includes(",") && thePrice.includes(".")) {
        thePrice = `R$ ${thePrice}`;
      } else {
        //
        thePrice = `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }

      return thePrice;
    },
    formatAnyPricePtBr(price) {
      let thePrice = 0;

      if (price) {
        thePrice = this.parseAnyNumber(price);
        thePrice = `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }

      return thePrice;
    },

    formatPricePtBr(price) {
      let thePrice = 0;
      if (price) thePrice = price;

      return `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`;
    },
    formatFloatPricePtBr(price) {
      let thePrice = 0;
      if (price) {
        thePrice = `${Number(price).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }
      return thePrice;
    },
    formatPriceCurrency(price, currency) {
      if (!price) {
        price = 0;
      }

      return `${Number(parseFloat(price).toFixed(2)).toLocaleString("pt-BR", {
        style: "currency",
        currency: currency,
      })}`;
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    formatContractStatus(status) {
      if (status === "new") {
        status = "Novo";
      }

      if (status === "concluded") {
        status = "Concluído";
      }

      if (status === "canceled") {
        status = "Cancelado";
      }

      if (status === "edit") {
        status = "Edição";
      }

      return status;
    },
    formatMultiDateTime(date) {
      if (date != undefined) {
        if (typeof date == "object") return format(date._d, "dd/MM/yyyy HH:mm");

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy HH:mm");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "dd/MM/yyyy HH:mm");
        }

        if (date.length == 10) {
          let theDate = date;

          if (date.includes("-")) {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy HH:mm");
          }

          return theDate;
        }

        if (date.length == 19)
          return format(new Date(date), "dd/MM/yyyy HH:mm");

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        )
          return format(new Date(date), "dd/MM/yyyy HH:mm");

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return `${format(theDate, "dd/MM/yyyy")} ${date
            .split("T")[1]
            .split(".")[0]
            .slice(0, -3)}`;
        }

        if (date.length == 25) {
          return format(new Date(date), "dd/MM/yyyy HH:mm");
        }
      }
    },
    formatMultiDates(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy/MM/dd HH:mm");
        }

        if (date.length == 10) {
          let theDate = date;

          if (date.includes("-")) {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy");
          }

          return theDate;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "dd/MM/yyyy");
        }

        if (date.length == 19) {
          //let theDate = parse(date.split("T")[0], "yyyy-MM-dd", );
          return format(new Date(date), "dd/MM/yyyy HH:mm");
        }

        if (date.length == 24 || date.length == 25) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
    formatMultiDateTimesToDate(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy/MM/dd");
        }

        if (date.length == 10) {
          let theDate = date;

          if (date.includes("-")) {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy");
          }

          return theDate;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "dd/MM/yyyy");
        }

        if (date.length == 19) {
          return format(new Date(date), "dd/MM/yyyy");
        }

        if (date.length == 24 || date.length == 25) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
    formatMultiDatesEng(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "yyyy-MM-dd");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "yyyy-MM-dd");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy-MM-dd HH:mm");
        }

        if (date.length == 10) {
          return date;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "yyyy-MM-dd");
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "yyyy-MM-dd");
        }
      }
    },
    formatInt(number) {
      return `${Number(parseFloat(number).toFixed(2)).toLocaleString()}`;
    },
    convertToInt(stringNumber) {
      if (stringNumber) {
        return parseInt(stringNumber.replace(/\./g, "").replace(" ", ""));
      }
    },
    convertMoneyToNumber(stringMoney) {
      let str = `${stringMoney}`;
      return Number(str.replace(/\./g, "").substr(3).replace(",", "."));
    },
    convertNumberToDatabase(stringMoney) {
      return stringMoney
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
    },
    formatCurrency(currency) {
      let str = ``;

      if (currency != undefined) {
        if (currency == "EUR") {
          str = "€";
        }

        if (currency == "USD") {
          str = "$";
        }

        if (currency == "BRL") {
          str = "R$";
        }
      } else {
        str = "R$";
      }

      return str;
    },
    unformatPrice(price) {
      return parseFloat(
        price.replace("R$ ", "").replace(".", "").replace(",", ".")
      );
    },
    unformatPtBrPrice(price) {
      const num = price
        ? parseFloat(price.replace(/[^\d,]/g, "").replace(",", ".")).toFixed(2)
        : 0;
      return num;
    },
    formatOperation(transaction) {
      let operation = "";
      let vcm = "";

      if (transaction.operation == "new") {
        if (transaction.is_vcm == "1") {
          vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
        }

        operation = `<font class="tag new">NOVO ${vcm}</font>`;
      }

      if (transaction.operation == "add") {
        operation = `<font class="tag add">ADIÇÃO</font>`;
      }

      if (transaction.operation == "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (transaction.operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "refund") {
        operation = `<font class="tag return">REEMBOLSO</font>`;
      }

      if (transaction.operation == "adjust") {
        operation = `<font class="tag adjust">AJUSTE</font>`;
      }

      if (transaction.operation == "purchase-canceled") {
        operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
      }

      return operation;
    },
    minuteWithZero(minute) {
      return ("0" + minute).slice(-2);
    },
    dayWithZero(day) {
      return ("0" + day).slice(-2);
    },
    monthWithZero(month) {
      return ("0" + month).slice(-2);
    },
    removeStrSpecialChars(string) {
      return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    formatMilesOperation(transaction) {
      let miles = "";
      if (transaction.operation == "new") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "add") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "return-miles") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "subtract") {
        miles = `-${transaction.total_miles}`;
      }
      return miles;
    },
    strToJson(arr) {
      return arr != undefined && arr != "" ? JSON.parse(arr) : [];
    },
    parseAnyNumber(value) {
      let theValue = 0;

      if (typeof value == "number") {
        theValue = value;
      }

      if (typeof value == "string") {
        if (value.includes(".") && !value.includes(",")) {
          let splitVal = value.split(".");

          if (value.length > 4) {
            if (splitVal[0].length >= 3) {
              theValue = parseFloat(value);
            } else {
              theValue = parseInt(value.replace(".", ""));
            }
          } else {
            theValue = parseInt(value);
          }
        } else if (value.includes(",") && !value.includes(".")) {
          theValue = parseValues(value);
        } else if (value.includes(",") && value.includes(".")) {
          theValue = value
            .replace(".", "")
            .replace(".", "")
            .replace(".", "")
            .replace(".", "");
        } else {
          theValue = parseInt(value);
        }
      }

      return theValue;
    },
  },
  methods: {
    translateMonths(month) {
      if (month === "January") return "Janeiro";
      if (month === "February") return "Fevereiro";
      if (month === "March") return "Março";
      if (month === "April") return "Abril";
      if (month === "May") return "Maio";
      if (month === "June") return "Junho";
      if (month === "July") return "Julho";
      if (month === "August") return "Agosto";
      if (month === "September") return "Setembro";
      if (month === "October") return "Outubro";
      if (month === "November") return "Novembro";
      if (month === "December") return "Dezembro";
    },
    formatValueToExcel(number) {
      let theNumber;
      if (typeof number === "number") theNumber = `${number}`.replace(".", ",");
      if (typeof number === "string") theNumber = number.replace(".", ",");
      return theNumber;
    },
    getNumberOfDaysAndNightsBetweenTwoDates(date1, date2) {
      // Dates in ISO 8601 format
      const startDate = new Date(date1);
      const endDate = new Date(date2);

      // Calculate the time difference in milliseconds
      const timeDifference = endDate.getTime() - startDate.getTime();

      // Calculate the number of nights
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      // Calculate the number of days (including the start date)
      const numberOfDays = numberOfNights + 1;

      return {
        nights: numberOfNights,
        days: numberOfDays,
      };
    },
    getTimeIntervalBetwennTwoDates(date1, date2) {
      const startDate = new Date(date1);
      const endDate = new Date(date2);

      const timeDifference = endDate - startDate;

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;

      return formattedTime; // Output ex: 01:05
    },
    removeLettersAndSpecialChars(str) {
      return str.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    formatDateTimeZeros(i) {
      if (i < 10) {
        return "0" + i;
      } else {
        return i;
      }
    },
    formatDateTimeObj(date) {
      return format(date, "dd/MM/yyyy HH:mm");
    },
    parseFloatToStr(value) {
      let theValue = 0,
        valuePieces,
        strValue = `${value}`;

      if (value && strValue.includes(".")) {
        valuePieces = strValue.split(".");
        theValue = `${valuePieces[0]},${
          valuePieces[1] ? valuePieces[1].slice(0, 2) : "00"
        }`;
      } else {
        theValue = `${value}`;
      }

      return theValue;
    },
    parseIntToStrWithZero(value) {
      let theValue = 0,
        strValue = `${value}`;

      if (!strValue.includes(",") || !strValue.includes(".")) {
        theValue;
      } else {
        theValue = `${value},00`;
      }

      return value;
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateReverse(date) {
      return format(date, "yyyy-MM-dd", {
        locale: ptBR,
      });
    },
    formatDateModel(date, model, separator) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, model, {
            locale: ptBR,
          });
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }

        if (date.length == 10) {
          return date.includes("-")
            ? date.split("-").reverse().join("/")
            : date;
        }

        separator;

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), model, {
            locale: ptBR,
          });
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }

        if (date.length == 25) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, model, {
            locale: ptBR,
          });
        }
      }
    },
    formatPriceBR(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString(
        "pt-BR",
        {}
      )}`;
    },
    formatPricePrBr(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`;
    },

    formatPercentage(percentage) {
      let thePercentage = 0;
      if (percentage) {
        thePercentage = percentage;
      }
      return `${Number(parseFloat(thePercentage).toFixed(2)).toLocaleString(
        "pt-BR"
      )}`;
    },
    formatMultiPricePtBr(price) {
      let thePrice = 0;
      if (price) {
        thePrice = price;
      }

      if (thePrice.includes(",") && thePrice.includes(".")) {
        thePrice = `R$ ${thePrice}`;
      } else {
        //
        thePrice = `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }

      return thePrice;
    },
    formatAnyPricePtBr(price) {
      let thePrice = 0;

      if (price) {
        thePrice = this.parseAnyNumber(price);

        thePrice = `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }

      return thePrice;
    },

    formatPricePtBr(price) {
      let thePrice = 0;
      if (price) {
        thePrice = price;
      }
      return `${Number(parseFloat(thePrice)).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`;
    },
    formatFloatPricePtBr(price) {
      let thePrice = 0;
      if (price) {
        thePrice = `${Number(price).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`;
      }
      return thePrice;
    },
    formatPriceCurrency(price, currency) {
      if (!price) {
        price = 0;
      }

      return `${Number(parseFloat(price).toFixed(2)).toLocaleString("pt-BR", {
        style: "currency",
        currency: currency,
      })}`;
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    formatContractStatus(status) {
      if (status === "new") {
        status = "Novo";
      }

      if (status === "concluded") {
        status = "Concluído";
      }

      if (status === "canceled") {
        status = "Cancelado";
      }

      if (status === "edit") {
        status = "Edição";
      }

      return status;
    },
    formatMultiDateTime(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy HH:mm");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy HH:mm");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "dd/MM/yyyy HH:mm");
        }

        if (date.length == 10) {
          //return date;

          let theDate = date;

          if (date.includes("-")) {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy HH:mm");
          }

          return theDate;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "dd/MM/yyyy HH:mm");
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy HH:mm");
        }
      }
    },
    formatMultiDates(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy/MM/dd HH:mm");
        }

        if (date.length == 10) {
          let theDate = date;

          if (date.includes("-") && date !== "0000-00-00") {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy");
          }

          if (date === "0000-00-00") return "";

          return theDate;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "dd/MM/yyyy");
        }

        if (date.length == 24 || date.length == 25) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
    formatMultiDatesEng(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "yyyy-MM-dd");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "yyyy-MM-dd");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy-MM-dd HH:mm");
        }

        if (date.length == 10) {
          return date;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "yyyy-MM-dd");
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "yyyy-MM-dd");
        }
      }
    },
    formatInt(number) {
      return `${Number(parseFloat(number).toFixed(2)).toLocaleString()}`;
    },
    convertToInt(stringNumber) {
      if (stringNumber) {
        return parseInt(stringNumber.replace(/\./g, "").replace(" ", ""));
      }
    },
    convertMoneyToNumber(stringMoney) {
      let str = `${stringMoney}`;
      return Number(str.replace(/\./g, "").substr(3).replace(",", "."));
    },
    convertNumberToDatabase(stringMoney) {
      return stringMoney
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
    },
    formatCurrency(currency) {
      let str = ``;

      if (currency != undefined) {
        if (currency == "EUR") {
          str = "€";
        }

        if (currency == "USD") {
          str = "$";
        }

        if (currency == "BRL") {
          str = "R$";
        }
      } else {
        str = "R$";
      }

      return str;
    },
    unformatPrice(price) {
      return parseFloat(
        price.replace("R$ ", "").replace(".", "").replace(",", ".")
      );
    },
    unformatPtBrPrice(price) {
      const num = price
        ? parseFloat(price.replace(/[^\d,]/g, "").replace(",", ".")).toFixed(2)
        : 0;
      return num;
    },
    formatOperation(transaction) {
      let operation = "";
      let vcm = "";

      if (transaction.operation == "new") {
        if (transaction.is_vcm == "1") {
          vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
        }

        operation = `<font class="tag new">NOVO ${vcm}</font>`;
      }

      if (transaction.operation == "add") {
        operation = `<font class="tag add">ADIÇÃO</font>`;
      }

      if (transaction.operation == "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (transaction.operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "refund") {
        operation = `<font class="tag return">REEMBOLSO</font>`;
      }

      if (transaction.operation == "adjust") {
        operation = `<font class="tag adjust">AJUSTE</font>`;
      }

      if (transaction.operation == "purchase-canceled") {
        operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
      }

      return operation;
    },
    minuteWithZero(minute) {
      return ("0" + minute).slice(-2);
    },
    dayWithZero(day) {
      return ("0" + day).slice(-2);
    },
    monthWithZero(month) {
      return ("0" + month).slice(-2);
    },
    removeStrSpecialChars(string) {
      return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    formatMilesOperation(transaction) {
      let miles = "";
      if (transaction.operation == "new") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "add") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "return-miles") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "subtract") {
        miles = `-${transaction.total_miles}`;
      }
      return miles;
    },
    strToJson(arr) {
      return arr != undefined && arr != "" ? JSON.parse(arr) : [];
    },
    parseAnyNumber(value) {
      let theValue = 0;

      if (typeof value == "number") {
        theValue = value;
      }

      if (typeof value == "string") {
        if (value.includes(".") && !value.includes(",")) {
          let splitVal = value.split(".");

          if (value.length > 4) {
            if (splitVal[0].length >= 3) {
              theValue = parseFloat(value);
            } else {
              theValue = parseInt(value.replace(".", ""));
            }
          } else {
            theValue = parseInt(value);
          }
        } else if (value.includes(",") && !value.includes(".")) {
          theValue = parseValues(value);
        } else if (value.includes(",") && value.includes(".")) {
          theValue = value
            .replace(".", "")
            .replace(".", "")
            .replace(".", "")
            .replace(".", "");
        } else {
          theValue = parseInt(value);
        }
      }

      return theValue;
    },
  },
};
